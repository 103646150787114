<template>
  <div class="node-list">
    <div class="node-item" :class="{'node-error': n.error}" v-for="(n, i) in list" :key="n.node_id">
      <div class="node-branch" v-if="n.type === 'branch'">
        <div class="branch-top">
          <div class="add-branch" @click="addBranch(n)" v-if="editable">添加条件</div>
          <div class="add-branch" v-else>条件分支</div>
        </div>
        <div class="branch-list">
          <div class="branch-item" :class="{'branch-first': j == 0, 'branch-last': j == n.children.length - 1}" v-for="(b, j) in n.children" :key="b.priority">
            <div class="branch-line-top"></div>
            <div class="branch-line-vertical"></div>
            <div class="branch-line-bottom"></div>
            <process-list
              :nodes="b.nodes"
              :branch="{parent: b.parent, default: b.default, priority: b.priority}"
              :editable="editable"
              :extra-temp="extraTemp"
              :has-level="hasLevel"
              @copy="handleCopy(j, n)"
              @edit="handleEdit($event, b, n)"
              @change="handleChange($event, i, j)"
              @remove="handleRemove(i, j)"></process-list>
            <div class="branch-line-lave"></div>
          </div>
        </div>
      </div>
      <template v-else-if="n.type === 'level'">
        <div class="node-ctx node-level">
          <div class="node-title">
            <span>同级审批</span>
            <div class="node-operate" v-if="editable">
              <a-icon type="close" class="node-close" @click.stop="removeNode(i)" />
            </div>
          </div>
        </div>
        <div class="node-branch">
          <div class="branch-list">
            <div class="branch-item" :class="{'branch-first': j == 0, 'branch-last': j == n.children.length - 1}" v-for="(temp, j) in n.children" :key="temp.id">
              <div class="branch-line-top"></div>
              <div class="branch-line-vertical"></div>
              <div class="branch-line-bottom"></div>
              <div class="level-child">
                <img :src="base + temp.icon" class="level-child-icon" v-if="temp.icon">
                <div class="level-child-name">{{ temp.name }}</div>
              </div>
              <process-list
                :nodes="temp.nodes"
                :branch="temp"
                :editable="editable"
                @copy="handleCopy(j, n)"
                @edit="handleEdit($event, temp, n, temp.id)"
                @change="handleChange($event, i, j)"
                @remove="handleRemove(i, j)"></process-list>
              <div class="branch-line-lave"></div>
            </div>
          </div>
        </div>
      </template>
      <div class="node-ctx node-apply" v-else-if="n.type === 0">
        <div class="node-title">申请人</div>
      </div>
      <div class="node-ctx" :class="[`node-type-${n.type}`]" @click="showEdit({node: n})" v-else>
        <a-icon type="exclamation-circle" theme="filled" class="node-error-icon" v-if="n.error"/>
        <div class="node-title">
          <span>{{n.title}}</span>
          <span class="node-priority" v-if="n.type == 'condition'">优先级{{branch ? branch.priority : ''}}</span>
          <div class="node-operate" v-if="editable">
            <div class="icon-copy node-copy" @click.stop="copyCondition" v-if="n.type == 'condition'"></div>
            <a-icon type="close" class="node-close" @click.stop="removeNode(i)" v-if="!(branch && branch.default && n.type == 'condition')"/>
          </div>
        </div>
        <div class="node-detail">
          <div class="node-set" v-if="n.type == 'condition'">
            <div v-if="branch && branch.default">未满足其他条件分支的情况，将使用默认流程</div>
            <div v-else-if="n.conditions && n.conditions.length > 0">
              <div>需满足以下条件：</div>
              <div v-for="con in n.conditions" :key="con.con_id">
                <span>{{con.label}}{{con.type == 6 ? `(${con.value.type == 1 ? '完全包括' : '其一包括'})` : ''}}：</span>
                <template v-if="con.type == 1">
                  <span v-for="b in con.value.applicant" :key="b.id">
                    <span class="text-span" v-if="b.type == 'tag'">{{b.name}}</span>
                    <open-data class="text-span" :type="b.type == 'user' ? 'userName' : 'departmentName'" :openid="b.id" v-else/>
                  </span>
                </template>
                <template v-else-if="con.type == 2">
                  <span class="text-span" v-for="t in con.value.typeText" :key="t">{{t}}</span>
                </template>
                <template v-else-if="con.type == 5 || con.type == 6">
                  <span class="text-span" v-for="t in con.value.select" :key="t">{{t}}</span>
                </template>
                <span v-else>{{getConditionNumberText(con.value.type) + (con.value.type == 6 ? con.value.number.replace(",", "到") : con.value.number)}}</span>
              </div>
            </div>
            <div v-else>请设置条件</div>
          </div>
          <div class="node-set" v-if="n.type == 1 || n.type == 2">
            <template v-if="n.leader_option > 0">
              <span v-if="n.leader_option == 1">直接上级</span>
              <span v-else-if="n.leader_option == 2">部门负责人</span>
              <span v-else-if="n.leader_option == 3 && n.tags">{{`[${n.tags[0].name}]标签内成员`}}</span>
              <span v-else-if="n.leader_option == 4">申请人自选 {{getElectTypeText(n.elect_type)}}{{n.elect_type == 1 ? '' : ' ' + getApproveTypeText(n.approval_type)}}</span>
              <span v-else-if="n.leader_option == 5">申请人本人</span>
              <span v-else-if="n.leader_option == 6">{{ n.group_name }}{{ n.volunteer_type_name }}</span>
              <span v-else-if="n.leader_option == 7">堂口主管</span>
              <span v-if="hasApprovalType(n)">{{ getApproveTypeText(n.approval_type) }}</span>
            </template>
            <template v-else-if="n.ids && n.ids.length > 0">
              <div class="node-person-list" v-if="n.type == 1">
                <span>指定成员{{n.ids.length > 1 ? getApproveTypeText(n.approval_type) : '审批'}}：</span>
                <span class="node-person-item" v-for="(id, i) in n.ids" :key="id">
                  <span v-if="i > 0">、</span>
                  <open-data type="userName" :openid="id"></open-data>
                </span>
              </div>
              <div class="node-person-list" v-else-if="n.type == 2">
                <span>指定抄送成员：</span>
                <span class="node-person-item" v-for="(id, i) in n.ids" :key="id">
                <span v-if="i > 0">、</span>
                <open-data type="userName" :openid="id"></open-data>
              </span>
              </div>
            </template>
            <span v-else-if="n.type == 1">请选择审批人</span>
            <span v-else-if="!n.custom_cc && !n.self_cc">请选择抄送人</span>
            <div v-if="n.custom_next">可指定下一个节点</div>
            <div v-if="n.custom_cc">申请人自选</div>
            <div v-if="n.self_cc">抄送给申请人本人</div>
          </div>
          <div class="node-set" v-else-if="n.type == 3">
            <div class="node-person-list" v-if="n.ids && n.ids.length > 0">
              <span>指定派单人：</span>
              <span class="node-person-item" v-for="(id, i) in n.ids" :key="id">
                <span v-if="i > 0">、</span>
                <open-data type="userName" :openid="id"></open-data>
              </span>
            </div>
            <span v-else>请选择派单人</span>
          </div>
          <div class="node-set" v-else-if="n.type == 4">
            <div class="node-person-list" v-if="n.ids && n.ids.length > 0">
              <span>指定接单人：</span>
              <span class="node-person-item" v-for="(id, i) in n.ids" :key="id">
                <span v-if="i > 0">、</span>
                <open-data type="userName" :openid="id"></open-data>
              </span>
            </div>
            <span v-else>请选择接单人</span>
          </div>
          <a-icon type="right" class="node-icon" v-if="editable && !(branch && branch.default && n.type == 'condition')"/>
        </div>
        <template v-if="editable">
          <a-icon type="up-circle" class="node-btn node-up" @click.stop="switchNode(i, -1)" v-if="i > 1 "/>
          <a-icon type="down-circle" class="node-btn node-down" @click.stop="switchNode(i, 1)" v-if="i !== list.length - 1 && n.type != 'condition'"/>
        </template>
      </div>
      <div class="node-next">
        <template v-if="editable">
          <a-icon type="plus-circle" theme="filled" class="node-next-add" @click.stop="addNode(i)" v-show="!n.custom_next"/>
          <div class="node-next-arrow" v-if="!(branch && i == list.length - 1)"></div>
          <div class="node-menu" v-if="ri === i">
            <div class="node-menu-item" :class="[`node-menu-type${r.type}`]" @click="selectNodeRole(r)" v-for="(r, j) in roles" :key="j">
              <div class="node-menu-icon" :class="[r.icon]"></div>
              <div class="node-menu-title">{{r.title}}</div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="node-item" v-if="!branch">
      <div class="node-ctx node-end">流程结束</div>
    </div>
  </div>
</template>

<script>
import {
  nodeRoles,
  getConditionNumberText,
  getNodeRoleName,
  getElectTypeText,
  getApproveTypeText,
  getNodeExtraTemp,
  hasApprovalType,
} from "../common/constant/process";
import {clone, randomString} from "../common/js/tool";
import {base} from "../components/icon-select";

const branchNodes = [{type: 1, title: '审批人',leader_option: 0, approval_type: 1}];

export default {
  name: "process-list",
  props: {
    branch: Object,
    editable: Boolean,
    extraTemp: Array,
    hasLevel: Boolean, // 是否已经添加过同级审批
    nodes: {
      type: Array,
      default() {
        return []
      }
    }
  },
  inject: {
      process: {
          form: "process",
          default: null
      }
  },
  data() {
    return {
      ri: null,
      list: [],
      base,
    }
  },
  computed: {
    roles() {
      const res = [
        ...nodeRoles.slice(0,3), // 接单节点由派单节点指定 不在流程中配置
        {type: 'branch', title: '条件分支', icon: 'icon-branch', name: 'branch'},
      ]
      if (!this.hasLevel && this.extraTemp && this.extraTemp.length > 0) {
        res.push({type: 'level', title: '同级审批', icon: 'icon-level', name: 'level'})
      }
      return res;
    }
  },
  watch: {
    nodes() {
      this.setList();
    }
  },
  created() {
    this.setList();
    document.addEventListener("click", this.hideAdd, false);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.hideAdd, false);
  },
  methods: {
    hasApprovalType,
    getElectTypeText,
    getApproveTypeText,
    getConditionNumberText,
    setList() {
      let list = this.nodes;
      let branch = this.branch;
      if(!branch && (!list || list.length <= 0 || list[0].type !== 0)) {
        list.unshift({type: 0, title: '申请人', name: 'apply'})
      }
      list.forEach(item => {
        if(item.type == 1 && item.leader_option == 3 && item.tag && !item.tags) {
          item.tags = [
            {...item.tag, tag_id: item.tag.id, real_ids: item.ids.join(",")}
          ];
          item.ids = [];
        } else if(item.type == 'level' && this.process) {
          this.process.hasLevel = true;
        }
      })
      this.list = list;
    },
    handleCopy(j, node) {
      let children = node.children;
      let branch = clone(children[j]);
      branch.default = false;
      branch.nodes.forEach(n => {
        if(n.type == 'condition') {
          // 复制不是默认条件
          if(j != children.length - 1) {
            branch.priority += 1;
          }
          n.title = n.title + '（复制）';
        }
        n.node_id = this.generateNodeId(getNodeRoleName(n.type));
      });
      // 复制的默认条件 将默认条件分支优先级+1 将新的分支插到默认分支前面
      if(j == children.length - 1) {
        children[j].priority += 1;
        children.splice(j, 0, branch);
      } else {
        //将当前分支后续所有分支优先级+1后 将新的分支插入到当前分支后面
        for(let i = j + 1, l = children.length; i < l; i++) {
          children[i].priority += 1;
        }
        children.splice(j + 1, 0, branch);
      }
      this.$set(node, 'children', children);
      this.dispatchChange();
    },
    handleEdit(e, branch, parent, tempId) {
      // 将分支、父级级信息带出
      e.branch = e.branch || branch;
      e.parent = e.parent || parent;
      // 同级审批模板
      if(tempId) {
        e.tempId = tempId;
      }
      this.$emit("edit", e);
    },
    handleChange(nodes, i, j) {
      let branch = this.list[i].children[j];
      this.$set(branch, 'nodes', nodes);
      this.dispatchChange();
    },
    handleRemove(i, j) {
      let node = this.list[i];
      let children = node.children;
      let branch = node.children[j];
      if(branch.default) {
        return;
      }
      // 删除之后只有默认分支 则将默认分支加入父级
      if(children.length == 2) {
        let nodes = children.find(b => b.default).nodes;
        nodes = nodes.filter(n => n.type != 'condition');
        this.list.splice(i, 1, ...nodes);
      } else {
        // 删除此分支并将次分支优先级后面的分支将优先级提升1位
        children.splice(j, 1);
        for(let k = j, l = children.length; k < l; k ++) {
          children[k].priority -= 1;
        }
        this.$set(node, 'children', children);
      }
      this.dispatchChange();
    },
    hideAdd() {
      this.ri = null;
    },
    showEdit({node}) {
      let branch = this.branch;
      if(node.type == 'condition' && branch && branch.default) return;
      this.$emit("edit", {node});
    },
    copyCondition() {
      this.$emit("copy");
    },
    removeNode(index) {
      let node = this.list[index];
      if(node.type == 'condition') {
        this.$confirm({
          title: '提示',
          content: '该条件分支下的所有节点将被删除，确认继续？',
          okText: '删除',
          onOk: () => {
            this.$emit("remove");
          }
        })
      } else {
        if(node.type == 'level') {
          this.process.hasLevel = false;
        }
        this.list.splice(index, 1);
        this.dispatchChange();
      }
    },
    addNode(index) {
      if(this.ri === index) {
        this.ri = null;
      } else {
        this.ri = index;
      }
    },
    setLevel(r) {
      let index = this.ri;
      let list = this.list;
      let node = {
        type: r.type,
        title: r.title,
        node_id: this.generateNodeId(r.name)
      }
      node.children = getNodeExtraTemp(this.extraTemp);
      list.splice(index + 1, 0, node);
      this.process.hasLevel = true;
      this.dispatchChange();
    },
    addBranch(node) {
      let children = node.children;
      let l = children.length;
      let branch = this.getBranchItem(l, node.node_id);
      // 更新默认分支的优先级
      this.$set(children[l - 1], 'priority', l + 1);
      // 插入新的分支
      children.splice(l - 1, 0, branch);
      this.dispatchChange();
    },
    setBranch(r) {
      let index = this.ri;
      let list = this.list;
      // 节点后剩余
      let last = list.splice(index + 1);
      let node = {
        type: r.type,
        title: r.title,
        node_id: this.generateNodeId(r.name)
      }
      let branch = this.getBranchItem(1, node.node_id);
      let defaultBranch = this.getBranchItem(2, node.node_id, last);
      node.children = [branch, defaultBranch];
      list.push(node);
      this.dispatchChange();
    },
    getBranchItem(priority, parent, last) {
      let branch = {
        parent: parent,
        priority: priority,
        nodes: [{
          type: 'condition',
          title: `条件${priority}`,
          name: 'condition',
          node_id: this.generateNodeId('condition')
        }]
      }
      if(last) {
        branch.default = true;
        branch.nodes[0].title = '默认条件';
        if(last.length <= 0) {
          last = this.getDefaultBranchNodes();
        }
      } else {
        last = this.getDefaultBranchNodes();
      }
      branch.nodes = branch.nodes.concat(last);
      return branch;
    },
    getDefaultBranchNodes() {
      let nodes = clone(branchNodes);
      nodes.forEach(n => {
        n.node_id = this.generateNodeId(getNodeRoleName(n.type));
      });
      return nodes;
    },
    selectNodeRole(r) {
      let index = this.ri;
      // 条件分支
      if(r.type === 'branch') {
        this.setBranch(r);
      } else if(r.type === 'level') {
        this.setLevel(r);
      } else {
        let list = this.list;
        if(r.type == 3) {
          if(list.find(n => n.type == 3)) {
            this.$message.warning("只能存在一个派单节点");
            return;
          }
        }
        let node = {
          type: r.type,
          title: r.title
        };
        if(node.type == 1 || node.type == 2) {
          node.leader_option = 0;
        }
        node.node_id = this.generateNodeId(r.name);
          // 审批节点
        if(r.type == 1) {
          // 默认多人审批方式为会签
          node.approval_type = 1;
          // 默认申请人自选为多选
          node.elect_type = 2;
        }
        list.splice(index + 1, 0, node);
      }
      this.ri = null;
      this.dispatchChange();
    },
    switchNode(index, num) {
      let list = this.list;
      if((num < 0 && index < 1) || (num > 0 && (index == list.length - 1 || list[index].type == 'condition'))) {
        return;
      }
      const item = list.splice(index, 1)[0];
      list.splice(index + num , 0, item);
      this.dispatchChange();
    },
    generateNodeId(name) {
      let str = `${name}_${randomString(6)}`;
      while (this.hasNode(str, this.list)) {
        str = this.generateNodeId(name);
      }
      return str;
    },
    hasNode(id, list) {
      let flag = false;
      for(let i = 0, l = list.length; i < l; i++) {
        let node = list[i];
        // 遍历分支下的节点
        if(node.type == 'branch') {
          let bf = false;
          for(let j = 0, len = node.children.length; j < len; j++) {
            let branch = node.children[j];
            if(this.hasNode(id, branch.nodes)) {
              bf = true;
              break;
            }
          }
          if(bf) {
            flag = true;
            break;
          }
        } else {
          if(node.node_id == id) {
            flag = true;
            break;
          }
        }
      }
      return flag;
    },
    dispatchChange() {
      this.$emit("change", this.list);
    }
  }
}
</script>

<style scoped lang="less">
  .node-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 0 24px;
  }
  .node-ctx {
    position: relative;
    width: 240px;
    background-color: #fff;
    box-shadow: 0 1px 8px 0 rgb(0 0 0 / 10%);
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      .node-btn {
        display: block;
      }
      .node-priority {
        display: none;
      }
      .node-operate {
        display: flex;
      }
    }
  }
  .node-error-icon {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(150%, 6px);
    font-size: 18px;
    color: @error-color;
  }
  .node-error .node-ctx{
    outline: 1px solid @error-color;
  }
  .node-btn {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 100%;
    z-index: 1;
    font-size: 20px;
    color: #999;
    &:hover {
      color: #2084CA;
    }
    &.node-up {
      top: -14px;
    }
    &.node-down {
      bottom: -14px;
    }
  }
  .node-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0 14px;
    height: 40px;
    border-top: 4px solid transparent;
    font-size: 14px;
  }
  .node-operate {
    display: none;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    right: 14px;
    height: 16px;
    font-size: 16px;
    color: #999
  }
  .node-copy {
    margin-right: 6px;
    font-size: 16px;
  }
  .node-close {
    width: 16px;
  }
  .node-apply {
    cursor: default;
    .node-title{
      border-top-color: #4A94FF;
      background: #E9F2FF;
      color: #4A94FF;
    }
  }
  .node-level {
    margin: 0 auto 50px;
    &:hover {
      box-shadow: 0 1px 8px 0 @lime-5;
    }
    .node-title{
      border-top-color: @lime-6;
      background: @lime-1;
      color: @lime-6;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -33px;
      margin: 0 auto;
      width: 2px;
      height: 33px;
      background-color: #dbdbdb;
    }
  }
  .node-type-1 {
    &:hover {
      box-shadow: 0 1px 8px 0 #fcad22;
    }
    .node-title {
      background-color: #FFF9EE;
      border-top-color: #FCAD22;
      color: #FCAD22;
    }
  }
  .node-type-2 {
    &:hover {
      box-shadow: 0 1px 8px 0 #3cb4b2;
    }
    .node-title {
      background-color: #E6F8F8;
      border-top-color: #3CB4B2;
      color: #3CB4B2;
    }
  }
  .node-type-3 {
    &:hover {
      box-shadow: 0 1px 8px 0 @volcano-6;
    }
    .node-title {
      background-color: #E6F8F8;
      border-top-color: @volcano-6;
      color: @volcano-6;
    }
  }
  .node-type-4 {
    &:hover {
      box-shadow: 0 1px 8px 0 @lime-6;
    }
    .node-title {
      background-color: #E6F8F8;
      border-top-color: @lime-6;
      color: @lime-6;
    }
  }
  .node-type-condition {
    &:hover {
      box-shadow: 0 1px 8px 0 #88939f;
    }
    .node-title {
      background-color: #EEF4FB;
      border-top-color: #88939F;
      color: #88939F;
    }
  }
  .text-span {
    margin-right: 4px;
  }
  .node-detail {
    display: flex;
    align-items: center;
    padding: 12px;
  }
  .node-set {
    flex: 1;
    line-height: 2;
  }
  .node-icon {
    color: #bbb;
  }
  .node-end {
    border: 0;
    height: 56px;
    font-size: 15px;
    line-height: 56px;
    text-align: center;
    cursor: default;
  }
  .node-next {
    position: relative;
    height: 100px;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      width: 2px;
      background-color: #dbdbdb;
    }
  }
  .node-next-add {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    margin: auto;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    font-size: 30px;
    color: #A0B5C8;
    &:hover {
      color: #8599AA;
    }
  }
  .node-next-arrow {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -4px;
    margin: 0 auto;
    width: 0;
    height: 4px;
    border-style: solid;
    border-width: 8px 5px 4px;
    border-color: #DBDBDB transparent transparent;
  }
  .node-menu {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    margin: auto 0;
    height: 74px;
    box-shadow: 0 1px 8px 0 rgb(0 0 0 / 10%);
    border-radius: 6px;
    background-color: #fff;
    transform: translateX(24px);
    z-index: 10;
    &::before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: -4px;
      bottom: 0;
      margin: auto 0;
      width: 8px;
      height: 8px;
      border-style: solid;
      border-width: 6px;
      border-color: #fff;
      transform: rotate(45deg);
    }
  }
  .node-menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 90px;
    height: 100%;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      background-color: #F9FBFD;
    }
    &:not(:first-of-type) {
      border-left: var(--border);
    }
    &.node-menu-type1 {
      color: #FCAD22;
    }
    &.node-menu-type2 {
      color: #3CB4B2;
    }
    &.node-menu-type3 {
      color: @volcano-6;
    }
    &.node-menu-type4 {
      color: @lime-6;
    }
    &.node-menu-type0 {
      color: @text-color-secondary;
    }
    &.node-menu-typelevel {
      color: @lime-6;
    }
  }
  .node-menu-icon {
    margin-bottom: 6px;
    font-size: 24px;
  }
  .branch-top {
    position: relative;
    z-index: 1;
  }
  .add-branch {
    margin: 0 auto;
    width: 84px;
    height: 34px;
    background-color: @component-background;
    border-radius: 4px;
    box-shadow: 0 1px 8px 0 rgb(0 0 0 / 10%);
    font-size: 14px;
    color: #4A94FF;
    text-align: center;
    line-height: 34px;
    cursor: pointer;
    &:hover {
      box-shadow: 0 1px 8px 0 rgb(0 0 0 / 20%);
    }
  }
  .branch-list {
    display: flex;
    margin-top: -17px;
  }
  .branch-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 50px 20px 0;
    &.branch-first {
      &>.branch-line-top::before,
      &>.branch-line-bottom::before {
        display: none;
      }
    }
    &.branch-last {
      &>.branch-line-top::after,
      &>.branch-line-bottom::after {
        display: none;
      }
    }
  }
  .branch-line-top,
  .branch-line-bottom {
    position: absolute;
    left: 0;
    right: 0;
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      height: 2px;
      background-color: #dbdbdb;
    }
    &::before {
      content: '';
      left: 0;
      right: 50%;
    }
    &::after {
      content: '';
      left: 50%;
      right: 0;
    }
  }
  .branch-line-top {
    top: 0;
  }
  .branch-line-bottom {
    bottom: 2px;
  }
  .branch-line-vertical {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 2px;
    height: 50px;
    background-color: #dbdbdb;
  }
  .branch-line-lave {
    flex: 1;
    width: 2px;
    background-color: #dbdbdb;
  }
  .level-child {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 50px;
    padding: 0 10px 0 6px;
    height: 30px;
    border-radius: 4px;
    border: var(--border);
    background-color: @component-background;
    font-size: 14px;
    text-align: center;
    line-height: 28px;
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -51px;
      margin: 0 auto;
      width: 2px;
      height: 50px;
      background-color: #dbdbdb;
    }
  }
  .level-child-icon {
    width: 24px;
    height: 24px;
  }
  .level-child-name {
    margin-left: 4px;
  }
</style>
